import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import layoutStyles from "./layout.module.scss"

const Nav = (props) => {
  const navRef = useRef()
  const navListRef = useRef()
  const highlightRef = useRef()
  const navArray = useRef({
    elementWidth: [],
    pageOffsetX: [],
    pageOffsetY: [],
  })
  const navToggleX = useRef()
  const navToggleMenu = useRef()
  const darken = useRef()
  const router = useRef()
  const pages = useRef(["/", "/about", "/timeline", "/message"])
  let navIsOpen = false;
  function moveNavHighlight(e) {
    highlightRef.current.style.transform = `${(window.innerWidth <= 800 ? ("translateY(" + navArray.current.pageOffsetY[e.target.index] + "px)") : "") + "translateX(" + navArray.current.pageOffsetX[e.target.index] + "px)"}`
    highlightRef.current.style.width = `${navArray.current.elementWidth[e.target.index] + "px"}`
  }
  function handleWindowResize() {
    if (window.innerWidth > 800) {
      closeNav()
      navRef.current.style.transform = ''
    }
    if (window.innerWidth <= 800) {
      navRef.current.classList.remove(layoutStyles.fnavTranslate)
    }
    darkenNavOnScroll()
    navArray.current = {
      elementWidth: [],
      pageOffsetX: [],
      pageOffsetY: [],
    }
    for (let i = 0; i < navListRef.current.children.length; i++) {
      if (window.innerWidth <= 800) {
        navArray.current.pageOffsetY.push(navListRef.current.children[i].getBoundingClientRect().bottom)
      }
      navArray.current.elementWidth.push(navListRef.current.children[i].clientWidth)
      navArray.current.pageOffsetX.push(navListRef.current.children[i].getBoundingClientRect().left)
    }
    updateNav()
  }
  function updateNav() {
    for (let i = 0; i < pages.current.length; i++){
      if (pages.current[i] === router.current) {
        highlightRef.current.style.transform = `${(window.innerWidth <= 800 ? ("translateY(" + navArray.current.pageOffsetY[i] + "px)"): "") + "translateX(" + navArray.current.pageOffsetX[i] + "px)"}`
        highlightRef.current.style.width = `${navArray.current.elementWidth[i] + "px"}`
        navListRef.current.children[i].style.fontWeight = "bold"
      } else {
        navListRef.current.children[i].style.fontWeight = "inherit"
      }
    }
  }
  function darkenNavOnScroll() {
    let calc;
    if (window.innerWidth <= 800) {
      calc = 0.9
    } else {
      calc = window.pageYOffset / document.body.clientHeight * 4
      if (calc > 0.9) {
        calc = 0.95
      }
    }
    if (props.theme !== null) {
      navRef.current.style.backgroundColor = `${(props.theme === "dark" ? "rgba(40,41,48, " : "rgba(250,247,240, ") + calc + ")"}`
    }
  }
  function toggleNav() {
    if (!navIsOpen) {
      navIsOpen = true
      navRef.current.style.transform = "translateX(0)"
      navToggleX.current.style.opacity = 1;
      navToggleMenu.current.style.opacity = 0;
      darken.current.style.backgroundColor = "rgba(0,0,0,0.5)"
      darken.current.style.backdropFilter = "blur(2px)"
    } else {
      closeNav()
    }
  }
  function closeNav() {
    navIsOpen = false
    navRef.current.style.transform = "translateX(-40vw)"
    navToggleX.current.style.opacity = 0;
    navToggleMenu.current.style.opacity = 1;
    darken.current.style.backgroundColor = "rgba(0,0,0,0)"
    darken.current.style.backdropFilter = "blur(0px)"
  }
  function handleKeyDownNav(e) {
    if (e.keyCode === 78) {
      toggleNav()
    }
  }
  useEffect(function () {
    router.current = window.location.pathname;
    closeNav()
    handleWindowResize()
    let navList = navListRef.current
    let nav = navRef.current
    for (let i = 0; i < navList.children.length; i++){
      for (let j = 0; j < navList.children[i].children.length; j++){
        navList.children[i].children[j].index = i
        navList.children[i].children[j].addEventListener("mouseenter",moveNavHighlight)
      }
    }
    window.addEventListener("scroll", darkenNavOnScroll)
    window.addEventListener('resize', handleWindowResize)
    nav.addEventListener("mouseleave", handleWindowResize)
    return () => {
      for (let i = 0; i < navList.children.length; i++){
        for (let j = 0; j < navList.children[i].length; j++){
          navList.children.children[i].index = i
          navList.children.children[j].addEventListener("mouseenter",moveNavHighlight)
        }
      }
      nav.removeEventListener("mouseleave", handleWindowResize)
      window.removeEventListener("scroll", darkenNavOnScroll)
      window.removeEventListener('resize', handleWindowResize)
    }
  })
  useEffect(function () {
    let unknownBugFixerHelpMePls;
    let fnavAnimation;
    if (localStorage.getItem("isShownAnimation") === "1") {
      if (window.innerWidth > 800) {
        navRef.current.classList.add(layoutStyles.fnavTranslate)
        unknownBugFixerHelpMePls = setTimeout(handleWindowResize,10)
      }
    } else {
      if (window.innerWidth > 800) {
        navRef.current.style.transform = "translateY(-3.5rem)"
        fnavAnimation = setTimeout(function () {
          navRef.current.classList.add(layoutStyles.fnavTranslate)
          unknownBugFixerHelpMePls = setTimeout(handleWindowResize,10)
        },3000)
      }
    }
    return (function () {
      clearTimeout(unknownBugFixerHelpMePls)
      clearTimeout(fnavAnimation)
    })
  }, [])
  const SwitchContainer = (props) => {
    const ThemeSwitch = (props) => {
      const toggleThemeBtn = useRef()
      const themeCarousell = useRef()
      function toggleTheme() {
        props.handleThemeChange(`${document.body.dataset.theme === "dark" ? "light" : "dark"}`)
      }
      useEffect(function () {
        let tTB = toggleThemeBtn.current
        tTB.addEventListener("click",toggleTheme)
        themeCarousell.current.style.transform = `${props.theme === "dark" ? "translateY(0px)" : (props.theme === "light" ? "translateY(-28px)" : "")}`
        return (function () {
          tTB.removeEventListener("click",toggleTheme)
        })
      },[])
      return (
        <div id={layoutStyles.themeSwitchContainer} className="hover-element" ref={toggleThemeBtn}>
          <div id={layoutStyles.themeSwitchCarousell} ref={themeCarousell}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#2f3038" stroke="#2f3038" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="--themeSwitchButton">
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#ffd45e" stroke="#ffd45e" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="themeSwitchButton">
              <circle cx="12" cy="12" r="5"></circle>
              <line x1="12" y1="1" x2="12" y2="3"></line>
              <line x1="12" y1="21" x2="12" y2="23"></line>
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
              <line x1="1" y1="12" x2="3" y2="12"></line>
              <line x1="21" y1="12" x2="23" y2="12"></line>
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
            </svg>
          </div>
        </div>
      )
    }
    const LangSwitch = (props) => {
      function langChange() {
        if (props.lang === "en") {
          props.handleLangChange("jp")
        } else if (props.lang === "jp") {
          props.handleLangChange("en")
        } else {
          console.log("one anticipates the forthcoming of nefarious calamities thy propenses to perpetrate")
        }
      }
      function handleKeyDownTheme(e) {
        if (e.keyCode === 76) {
          langChange()
        }
      }
      return (
        <div id={layoutStyles.langSwitch} className="hover-element-lang hover-element">
          <p onClick={langChange} onKeyDown={handleKeyDownTheme}>{props.lang === "en" ? "日本語" : (props.lang === "jp" ? "EN" : "")}</p>
        </div>
      )
    }
    return (
      <div id={layoutStyles.switchContainer}>
        <LangSwitch lang={props.lang} handleLangChange={props.handleLangChange} data={props.data}/>
        <ThemeSwitch theme={props.theme} handleThemeChange={props.handleThemeChange} />
      </div>
    )
  }
  return (
    <>
      <div id={layoutStyles.nav} ref={navRef}>
        <ul id={layoutStyles.navListContainer} ref={navListRef}>
          <Link to="/" className={layoutStyles.link}>
            <li className={layoutStyles.navList + " hover-element"}>
              {props.lang === "en" ? "Home" : ""}
              {props.lang === "jp" ? "ホーム" : ""}
            </li>
          </Link>
          <Link to="/about" className={layoutStyles.link}>
            <li className={layoutStyles.navList + " hover-element"}>
              {props.lang === "en" ? "About" : ""}
              {props.lang === "jp" ? "僕について" : ""}
            </li>
          </Link>
          <Link to="/timeline" className={layoutStyles.link}>
            <li className={layoutStyles.navList + " hover-element"}>
              {props.lang === "en" ? "Timeline" : ""}
              {props.lang === "jp" ? "タイムライン" : ""}
            </li>
          </Link>
          <Link to="/message" className={layoutStyles.link}>
            <li className={layoutStyles.navList + " hover-element"}>
              {props.lang === "en" ? "Message" : ""}
              {props.lang === "jp" ? "連絡" : ""}
            </li>
          </Link>
        </ul>
        <SwitchContainer lang={props.lang} theme={props.theme} handleLangChange={props.handleLangChange} handleThemeChange={props.handleThemeChange}/>
        <span id={layoutStyles.navHighlight} ref={highlightRef} />
      </div>
      <div id={layoutStyles.navSwitch} className="hover-element" onClick={toggleNav} onKeyDown={handleKeyDownNav} role="button" tabIndex={0}>
        <div id={layoutStyles.navSwitchContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ref={navToggleX} className={layoutStyles.navToggleIcon}><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ref={navToggleMenu} className={layoutStyles.navToggleIcon}><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
        </div>
      </div>
      <div id={layoutStyles.darken} ref={darken}/>
    </>
  )
}

const Graphics = () => {
  const canvas = useRef(),
    canvasBg = useRef(),
    fpsCounter = useRef();
  useEffect(() => {
    let ani;
    const ctx = canvas.current.getContext("2d"),
      ctxBg = canvasBg.current.getContext("2d");
    const dimensions = {
      width: 0,
      height: 0,
    }
    let starCounter = Math.floor(Math.sqrt(window.innerWidth * window.innerWidth + window.innerHeight * window.innerHeight) / 10)
    const starTypes = {
      near:{
        count:Math.floor(0.1*starCounter),
        zMin:10,
        zMax:15,
        alpha:0.8,
      },
      medium:{
        count:Math.floor(0.3*starCounter),
        zMin:18,
        zMax:25,
        alpha:0.7,
      },
      far:{
        count:Math.floor(0.6*starCounter),
        zMin:30,
        zMax:50,
        alpha:0.5,
      }
    }
    const cursorAttributes = {
      width:0,
      x:0,
      y:0,
      radius:0,
    }
    let starArray = [],
      tiltX = 0,
      tiltY = 0,
      cWidth,
      cHeight,
      cursorVelocity,
      themeChangeInterval,
      x0 = 0,
      x1 = 0,
      y0 = 0,
      y1 = 0,
      coBool,
      disableInput = true,
      timeoutInput,
      ctxBgColor,
      starColor,
      t0 = 0,
      t1 = 0,
      targetFPS = "max",
      interval,
      prevStart = 0,
      nowStart = 0,
      deltaTime,
      currentTheme = document.body.dataset.theme,
      star = {
        x:0,
        y:0,
        z:0,
        velocityX:0,
        velocityY:0,
        velocityXMemo:0,
        velocityYMemo:0,
        radius:0,
        elapsedLife:0,
        life:0,
        alpha:0,
        isSwirling:false,
        swirlCount:0,
        swirlMagnitude:0,
        swirlIterate:0,
        swirlDeltaDistanceX:0,
        swirlDeltaDistanceY: 0,
        swirlMultiplier: 0,
        create:function(type){
          let obj = Object.create(this)
          let coordinates = this.getCoordinates(type)
          for(let prop in coordinates){
            obj[prop] = coordinates[prop] //set spawn coordinates
          }
          let otherProps = this.getOtherProps(obj,type)
          for(let prop in otherProps){
            obj[prop] = otherProps[prop]
          }
          return obj
        },
        getCoordinates:function(type){
          let vect = {
            x:Math.floor(Math.random() * canvas.current.width * 0.8 + canvas.current.width * 0.1),
            y:Math.floor(Math.random() * canvas.current.height * 0.8 + canvas.current.height * 0.1),    
            z:(Math.floor(Math.random() * starTypes[type].zMax * 100)/100)+starTypes[type].zMin
          }
          return vect
        },
        getVelocity:function(object){
          let num = Math.floor((Math.random() - 0.5) * 500)/(2000*object.z)
          while(num === 0){
            num = this.getVelocity(object)
          }
          if(num < 0){
            num -= 0.05/object.z
          }
          if(num > 0){
            num += 0.05/object.z
          }
          return num
        },
        getOtherProps:function(object,type){
          let vect = {
            velocityX:this.getVelocity(object),
            velocityY:this.getVelocity(object),
            velocityXMemo:this.velocityX,
            velocityYMemo:this.velocityY,
            radius:25/object.z,
            elapsedLife:Math.floor(((Math.random() + 1) * 5 + 5)*200*(object.z/4)),
            life:Math.floor(((Math.random() + 1) * 5 + 5)*200*(object.z/4)),
            alpha:starTypes[type].alpha,
            isNewBorn:false,
          }
          return vect
        },
      }
    function swirlDust(star,cursorVelocity){
      let x = star.x - cursorAttributes.x,
          y = star.y - cursorAttributes.y,
          r = star.radius + cursorAttributes.radius;
      if (r * 2 > Math.sqrt((x * x) + (y * y))) {
        star.atan2Value = (Math.atan2(-star.velocityY,star.velocityX)*180 / Math.PI)
        if(cursorVelocity >= 2 && cursorVelocity < 100){
          if(!star.isSwirling){
            star.isSwirling = true
            star.swirlMagnitude = cursorVelocity / 10
            star.swirlCount = Math.floor((Math.random()*300+180)*(star.swirlMagnitude+1)/2)
            star.swirlIterate = star.swirlCount
            star.swirlDeltaDistanceX = x
            star.swirlDeltaDistanceY = y
            star.swirlMultiplier = (Math.random()/2)+0.5
          }
        }
      }
    }
    function drawBg() {
      ctxBg.clearRect(0,0,cWidth,cHeight)
      ctxBg.fillStyle = `rgb(${ctxBgColor})`
      ctxBg.fillRect(0,0,cWidth,cHeight) //pre-render bg
    }
    
    function drawStar(star){
      ctx.beginPath()
      ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2, true)
      ctx.fillStyle = `rgba(${starColor + "," +star.alpha})`
      ctx.fill()
    }
    function newBornStar(star){
      star.velocityX = star.getVelocity(star)
      star.velocityY = star.getVelocity(star)
      star.velocityXMemo = star.velocityX
      star.velocityYMemo = star.velocityY
      star.x = Math.floor(Math.random() * canvas.current.width * 0.8 + canvas.current.width * 0.1)
      star.y = Math.floor(Math.random() * canvas.current.height * 0.8 + canvas.current.height * 0.1)
      star.elapsedLife = 0
      star.life = Math.floor(((Math.random() + 1) * 5 + 5)*200*(star.z/4))
      star.isNewBorn = true
      star.isSwirling = false
      star.swirlCount = 0
      star.swirlIterate = 0
      star.swirlMagnitude = 0
      star.swirlDeltaDistanceX = 0
      star.swirlDeltaDistanceY = 0
      star.swirlMultiplier = 0
    }
    let rootFontSize;
    function updateWindowProps(){
      dimensions.width = canvas.current.clientWidth
      dimensions.height = canvas.current.clientHeight
      cWidth = canvas.current.width = window.innerWidth
      cHeight = canvas.current.height = window.innerHeight
      rootFontSize = parseFloat(window.getComputedStyle(document.body).getPropertyValue("font-size").replace("px", ""))
    }
    function tilt(e){ 
      let x = dimensions.width * 16 * (e.clientX / dimensions.width - 0.5)
      let y = dimensions.height * 16 * (e.clientY / dimensions.height - 0.5)
      tiltX += (x - tiltX)/32
      tiltY += (y - tiltY)/32
    }
    function mouseAttributesGetter(e) {
      if (e) {
        tilt(e)
        cursorAttributes.width = rootFontSize * 0.4 + 24 //0.4 is rem in cursor width
        cursorAttributes.radius = cursorAttributes.width/2 - 1
        cursorAttributes.x = e.clientX
        cursorAttributes.y = e.clientY
      }
      disableInput = false
      clearTimeout(timeoutInput)
      timeoutInput = setTimeout(function(){
        disableInput = true
      },500)
    }
    updateWindowProps()
    function update(timestamp) {
      
      // -- fps & timescale -- //
      prevStart = nowStart = timestamp
      while((nowStart - prevStart) < interval){
        nowStart = performance.now()
      }
      if(t0<=t1){
        t0 = nowStart
      }else if(t0>t1){
        t1 = nowStart
      }
      if(!disableInput){
        if(coBool){
          x1 = cursorAttributes.x
          y1 = cursorAttributes.y
          coBool = false
        }else{
          x0 = cursorAttributes.x
          y0 = cursorAttributes.y
          coBool = true
        }
        cursorVelocity = Math.sqrt((x0-x1)*(x0-x1)+(y0-y1)*(y0-y1))
      }
      deltaTime = Math.abs(t1-t0)
      fpsCounter.current.innerHTML = `FPS: ${Math.floor(1000000/deltaTime)/1000}`
      // -- graphics paint -- //
      let theme = document.body.dataset.theme
      if (theme !== currentTheme) { //change star & bg color theme
        clearInterval(themeChangeInterval)
        let ctxBgColorNums, starColorNums,
          ctxBgCurrentColor = ctxBgColor.replace(/\s/g,"").split(","),
          starCurrentColor = starColor.replace(/\s/g, "").split(",");
        if (theme === "dark") {
          ctxBgColorNums = [40,41,48]
          starColorNums = [240, 234, 221]
        } else {
          starColorNums = [20,21,24]
          ctxBgColorNums = [250,247,240]
        }
        let ctxBgColorDifference = [0,0,0],
          starColorDifference = [0,0,0],
          i = 0;
        while (i < 3) {
          ctxBgCurrentColor[i] = parseFloat(ctxBgCurrentColor[i]) 
          starCurrentColor[i] = parseFloat(starCurrentColor[i])
          ctxBgColorDifference[i] = ctxBgColorNums[i] - ctxBgCurrentColor[i] 
          starColorDifference[i] = starColorNums[i] - starCurrentColor[i]
          i++
        }
        let iterate = 0;
        themeChangeInterval = setInterval(function () { //transition color
          if (iterate < 30) {
            let i = 0;
            while (i < 3) {
              ctxBgCurrentColor[i] += ctxBgColorDifference[i] / 30
              starCurrentColor[i] += starColorDifference[i]/30
              i++
            }
            iterate++
          }
          ctxBgColor = ctxBgCurrentColor.toString()
          starColor = starCurrentColor.toString()
          drawBg()
        },10)
        currentTheme = document.body.dataset.theme
      }
      ctx.clearRect(0,0,cWidth,cHeight)
      for(let i=0;i<starArray.length;i++){
        let star = starArray[i]
        //swirl
        swirlDust(star,cursorVelocity)
        if(star.isSwirling){
          if(star.swirlIterate > 0){
            if(star.isNewBorn){
              star.elapsedLife += star.swirlMagnitude*40
            }else{
              star.elapsedLife -= star.swirlMagnitude*40
            }
            let rads;
            if(Math.abs(star.atan2Value) < 90){
              rads = -(star.atan2Value+star.swirlIterate)*Math.PI/360
            }else{
              rads = (star.atan2Value+star.swirlIterate)*Math.PI/360
            }
            star.velocityX = (star.velocityXMemo + (Math.cos(rads)/5 * star.swirlMagnitude*1.2))*5/star.z+(star.swirlDeltaDistanceX/300)
            star.velocityY = (star.velocityYMemo + (Math.sin(rads)/5 * star.swirlMagnitude*1.2))*5/star.z+(star.swirlDeltaDistanceY/300)
            star.swirlIterate -= star.swirlMultiplier+(Math.random())*deltaTime+(star.swirlIterate*8/star.swirlCount)*star.swirlMagnitude/2
          }else{
            star.isSwirling = false;
            star.swirlCount = 0;
          }
        }
        if(!star.isSwirling){
          if(Math.abs(star.velocityX/star.velocityXMemo) > 1.1 || Math.abs(star.velocityY/star.velocityYMemo) > 1.1){
            if(Math.abs(star.velocityX/star.velocityXMemo) > 1.1){
              star.velocityX *= (1 - 0.01*(star.swirlMagnitude+0.2)/2*(deltaTime/24))
            }
            if(Math.abs(star.velocityY/star.velocityYMemo) > 1.1){
              star.velocityY *= (1 - 0.01*(star.swirlMagnitude+0.2)/2*(deltaTime/6))
            }
          }
        }
        // end of swirl
        if(star.x > canvas.current.width * 1.2|| star.y > canvas.current.height * 1.2|| star.x < 0 - canvas.current.width * 0.2 || star.y < 0 - canvas.current.width * 0.2){
          newBornStar(star)
        }
        star.x += (star.velocityX * deltaTime)
        star.y += (star.velocityY * deltaTime)
        if(star.elapsedLife + deltaTime > star.life){
          if(star.isNewBorn){
            star.elapsedLife = 0
            star.isNewBorn = false
          }else{
            newBornStar(star)
          }
        }
        star.elapsedLife += deltaTime
        if(star.isNewBorn){
          star.alpha = (star.elapsedLife / star.life)
        }else{
          star.alpha = 1 - (star.elapsedLife / star.life)
        }
        star.x += tiltX/(star.z*star.z)
        star.y += tiltY/(star.z*star.z)
        drawStar(star)
        star.x -= tiltX/(star.z*star.z)
        star.y -= tiltY/(star.z*star.z)
      }
      ani = requestAnimationFrame(update)
    }
    function init() {
      if (document.body.dataset.theme === "dark") {
        ctxBgColor = "40, 41, 48"
        starColor = "250, 247, 240"
      } else {
        ctxBgColor = "250, 247, 240"
        starColor = "40, 41, 48"
      }
      cWidth = canvas.current.width = window.innerWidth
      cHeight = canvas.current.height = window.innerHeight
      drawBg()
      if(targetFPS === "max" || targetFPS > 0){
        interval = 1000/targetFPS 
      }
      for(let prop in starTypes){    // stars
        for(let i=0;i<starTypes[prop].count;i++){
          let stars = star.create(prop)
          starArray.push(stars)
        }
      }
      updateWindowProps()
      ani = requestAnimationFrame(update)
    }
    init()
    let canvasTimeout;
    if (localStorage.getItem("isShownAnimation") === "1") {
      canvas.current.style.filter = "blur(0px)"
      canvas.current.style.transform = "scale(1)"
    } else {
      canvasTimeout = setTimeout(function () {
        canvas.current.classList.add("fcanvasBlur")
        localStorage.setItem("isShownAnimation","1")
      },3000)
    }
    window.addEventListener("mousemove",mouseAttributesGetter)
    window.addEventListener('resize', updateWindowProps)
    return (function () {
      clearTimeout(canvasTimeout)
      clearInterval(themeChangeInterval)
      window.cancelAnimationFrame(ani)
      window.removeEventListener("mousemove",mouseAttributesGetter)
      window.removeEventListener('resize', updateWindowProps)
    })
  }, [])
  return (
    <>
      <canvas id={layoutStyles.canvasBg} ref={canvasBg}></canvas>
      <canvas id={layoutStyles.canvasMain} ref={canvas}></canvas>
      <p id={layoutStyles.fpsCounter} ref={fpsCounter}>fps counter</p>
    </>
  )
}
const Cursor = () => {
  const cursor = useRef()
  useEffect(() => {
    function mouseMoveHandler(e) {
      cursor.current.style.left = `${e.clientX + "px"}`
      cursor.current.style.top = `${e.clientY + "px"}`
    }
    document.body.addEventListener("mousemove",mouseMoveHandler)
    function changeCursorOnHover() {
      cursor.current.classList.add("isHovering")
    }
    function changeCursorOnLeave() {
      cursor.current.classList.remove("isHovering")
    }
    function changeCursorOnHoverLang() {
      cursor.current.classList.add("isHoveringLang")
    }
    function changeCursorOnLeaveLang() {
      cursor.current.classList.remove("isHoveringLang")
    }
    let hoverElementArray = document.getElementsByClassName("hover-element")
    for (let i = 0; i < hoverElementArray.length; i++){
      hoverElementArray[i].addEventListener("mouseenter", changeCursorOnHover)
      hoverElementArray[i].addEventListener("mouseleave", changeCursorOnLeave)
    }
    let hoverLangElementArray = document.getElementsByClassName("hover-element-lang")
    for (let i = 0; i < hoverLangElementArray.length; i++){
      hoverLangElementArray[i].addEventListener("mouseenter", changeCursorOnHoverLang)
      hoverLangElementArray[i].addEventListener("mouseleave", changeCursorOnLeaveLang)
    }
    return (function () {
      for (let i = 0; i < hoverElementArray.length; i++){
        hoverElementArray[i].removeEventListener("mouseenter", changeCursorOnHover)
        hoverElementArray[i].removeEventListener("mouseleave", changeCursorOnLeave)
      }
      document.body.removeEventListener("mousemove",mouseMoveHandler)
    })
  })
  return <div id="cursor" ref={cursor}></div>
}

export default function Layout(props) {
  const [Lang, setLang] = useState(null)
  const [Theme, setTheme] = useState(null)
  useEffect(() => {
    if (Lang !== null) {
      localStorage.setItem("lang", Lang)
    }
    if (Theme !== null) {
      document.body.dataset.theme = Theme
      localStorage.setItem("theme", Theme)
    }
  })
  useEffect(function () {
    if (localStorage.getItem("lang") === "en" || localStorage.getItem("lang") === "jp") {
      setLang(localStorage.getItem("lang"))
    } else {
      setLang("en")
    }
    if (localStorage.getItem("theme") === "dark" || localStorage.getItem("theme") === "light") {
      setTheme(localStorage.getItem("theme"))
    } else {
      setTheme("dark")
    }
    window.addEventListener("unload", function () {
      localStorage.setItem("isNotFirstVisit","1")
    })
    const root = document.querySelector("html")
    function calcFontInt() {
      if (window.innerWidth >= 1400){
        root.style.fontSize = `${Math.round((0.7*window.innerWidth + 0.3*window.innerHeight + 800)/100) + "px"}`
      } else if(window.innerWidth >= 1200){
        root.style.fontSize = `${Math.round((0.4*window.innerWidth + 0.6*window.innerHeight + 1000)/100) + "px"}`
      } else if (window.innerWidth >= 800) {
        root.style.fontSize = `${Math.round((0.3*window.innerWidth + 0.7*window.innerHeight + 1000)/100) + "px"}`
      } 
    }
    calcFontInt()
    window.addEventListener("resize", calcFontInt)
    return (function () {
      window.removeEventListener("resize", calcFontInt)
      window.removeEventListener("unload", function () {
        localStorage.setItem("isNotFirstVisit","1")
      })
    })
  }, [])
  const Child = () => {
    return (
      <div className={layoutStyles.globalMargin}>
        {React.cloneElement(props.children, { lang: Lang })}
      </div>
    )
  }
  return (
    <>
      <Graphics/>
      <Nav lang={Lang} theme={Theme} handleLangChange={(lang) => setLang(lang)} handleThemeChange={(theme) => setTheme(theme)} data={props.data}/>
      <Cursor />
      <Child />
    </>
  )
}


